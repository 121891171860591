.wrapper {
  display: none;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    width: 100%;
    margin: 20px auto;
    height: auto;
    color: var(--primaryText);
    display: inline-block;
    text-align: center;
  }
}
