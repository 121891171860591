.previous {
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

.previous:hover {
  cursor: pointer;
  transform: scale(1.2);
}

/* @media only screen and (max-width: 800px) {
  .previous {
    width: 25px;
    height: 25px;
  }
} */