.wrapper {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(5, auto);
  place-items: center;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
    grid-template-columns: repeat(3, auto);
  }
}
