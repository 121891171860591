.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 0;
  }
}
