.tag {
  background-color: var(--tagsBackground);
  color: var(--tagsText);
  height: 40px;
  min-width: 100px;
  display: inline-grid;
  place-items: center;
  margin: 5px 5px;
  transition: transform 0.2s;
  padding: 0 10px;
  font-family: 'Varela Round', sans-serif;
  border-radius: 10px;
  font-size: 18px;
}

.active {
  background-color: var(--tagsBackgroundHoverActive);
  color: var(--tagsTextHoverActive);
  height: 40px;
  min-width: 100px;
  display: inline-grid;
  place-items: center;
  margin: 0 5px;
  transition: transform 0.3s;
  padding: 0 10px;
  font-family: 'Varela Round', sans-serif;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
}

.tag:hover {
  background-color: var(--tagsBackgroundHoverActive);
  color: var(--tagsTextHoverActive);
  cursor: pointer;
  transform: scale(1.1);
}
