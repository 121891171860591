.wrapper {
  width: 100%;
  margin: 20px auto 20px auto;
  max-height: 425px;
  min-height: 120px;
  overflow-x: hidden;
  padding-right: 10px;
  font-family: "Quicksand", sans-serif;
}

/* width */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.wrapper::-webkit-scrollbar-thumb {
  background: var(--primaryText);
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    width: 90%;
  }
}
