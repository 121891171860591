.container {
  display: grid;
  place-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--progressSlider);
  cursor: pointer;
}

.slider::-webkit-slider-thumb:hover {
  background: var(--progressSlider);
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--progressSlider);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 40px 0;
  }
}
