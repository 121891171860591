@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
} */

.title_time_wrapper {
  display: grid;
  grid-template-columns: auto 200px;
  margin: 30px 0 20px 0;
}

.buttons_volume_wrapper {
  display: grid;
  grid-template-columns: auto 30%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 800px) {
  .title_time_wrapper {
    grid-template-columns: 100%;
  }

  .buttons_volume_wrapper {
    grid-template-columns: 100%;
  }
}
