.wrapper {
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 60px;
}

.slider {
  -webkit-appearance: none;
  width: 70%;
  height: 3px;
  border-radius: 5px;
  background: var(--volumeSlider);
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--volumeSlider);
  cursor: pointer;
}

.slider::-webkit-slider-thumb:hover {
  background: var(--volumeSlider);
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--volumeSlider);
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .wrapper {
    display: none;
    /* margin: 10px 0 0 0; */
  }
}
