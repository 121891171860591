.pause {
  width: 60px;
  height: 60px;
  transition: transform 0.2s;
}

.pause:hover {
  cursor: pointer;
  transform: scale(1.2);
}

/* @media only screen and (max-width: 800px) {
  .pause {
    width: 30px;
    height: 30px;
  }
} */