.track {
  /* background-color: var(--playlistBackground); */
  background-color: transparent;
  color: var(--playlistText);
  font-family: "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  font-size: 15px;
  margin: 5px 0;
  /* padding: 3px 0; */
  padding: 0;
  cursor: pointer;
  border-radius: 5px;
}

.track:first-child {
  margin: 0;
}

.active {
  background-color: var(--playlistBackground);
  color: var(--playlistTextHoverActive);
  text-align: center;
  font-size: 16px;
  margin: 5px 0;
  /* padding: 3px 0; */
  padding: 0;
  cursor: pointer;

  border-radius: 5px;
}

.active:first-child {
  margin: 0;
}

.track:hover {
  background-color: var(--playlistBackground);
  /* background-color: transparent; */
  color: var(--playlistTextHoverActive);
  border-radius: 5px;
}
