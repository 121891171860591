.time {
  font-family: 'Varela Round', sans-serif;
  color: var(--timeColor);
  text-align: right;
  font-size: 30px;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .time {
    width: 100%;
    margin: 10px 0 0 0;
    font-size: 12px;
    text-align: center;
    /* display: none; */
  }
}
