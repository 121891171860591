.shuffle {
  width: 26px;
  height: 26px;
  transition: transform 0.2s;
}

.shuffle:hover {
  cursor: pointer;
  transform: scale(1.2);
}

@media only screen and (max-width: 600px) {
  .shuffle {
    display: none;
  }
}
